import React from 'react'
import {ContentBody} from 'dc-delivery-sdk-js'
import {observer} from 'mobx-react'
import {Box, Flex, Stack} from '../../vanilla'
import {OfferCard, OfferCardCmsContent} from './OfferCard'
import {SlotComponent} from '../../types/ui'
import {Carousel} from '../carousel'
import {categoryTileTitle} from './category-tiles/styles.css'
import {offerCardList} from './styles.css'

export interface OfferCardsCmsContent {
  isMobileCarousel: boolean
  cardList: Array<OfferCardCmsContent & ContentBody>
  title?: string
}

export const OfferCards: SlotComponent<OfferCardsCmsContent> = observer(({content}) => {
  const {cardList, isMobileCarousel, title, slotGroupName} = content
  return (
    <Box>
      {title && (
        <Stack align="stretch" marginY="16px">
          <Flex>
            <h2
              style={{
                width: '100%',
                textAlign: 'center',
                margin: '10px 0 20px',
              }}
            >
              <span className={categoryTileTitle}>{title}</span>
            </h2>
          </Flex>
        </Stack>
      )}
      <Box
        flexDirection={['column', 'row']}
        display={cardList?.length === 1 || !isMobileCarousel ? 'flex' : ['none', 'flex']}
        width="full"
        gap={["10px", "20px"]}
        className={offerCardList}
      >
        {cardList?.map((card, index) => {
          return (
            <OfferCard
              key={card._meta.deliveryId}
              content={card}
              styles={{width: '100%', height: 'auto'}}
              size={cardList.length}
              index={index + 1}
              isOfferCards={true}
              offerCardsSlotGroupName={slotGroupName}
            />
          )
        })}
      </Box>
      <Box display={cardList?.length === 1 || !isMobileCarousel ? 'none' : ['block', 'none']}>
        <Carousel
          loop={false}
          itemsPerSide={{desktop: 1, mobile: 1, tablet: 1}}
          controlsPosition="innerBottom"
          controlConfig={{
            activeDotColor: 'accent0',
            arrowsBgColor: 'gray600',
            arrowsColor: 'white',
            dotsOutline: true,
            showArrows: {mobile: false, desktop: true, tablet: true},
          }}
        >
          {cardList?.map((card, index) => {
            return (
              <OfferCard
                key={card._meta.deliveryId}
                content={card}
                styles={{width: '100%', height: 'auto', flexShrink: 0}}
                index={index + 1}
                isOfferCards={true}
                offerCardsSlotGroupName={slotGroupName}
              />
            )
          })}
        </Carousel>
      </Box>
    </Box>
  )
})
