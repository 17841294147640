import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {Container, Stack, Box} from '../../vanilla'
import {Breadcrumbs, Crumb} from '../../components/breadcrumbs'
import {Slot} from '../../components/cms/Slot'
import {SlotItem, SlotContentItem} from '../../types/cms'
import {ContentBody} from 'dc-delivery-sdk-js'
import {Seo} from '../../components/seo'
import {usePageDataTracking} from '../../analytics/page-tracking'
import {useCategoryStore} from '../../store/hooks/useStore'
import {usePromotionDataTracking} from '../../analytics/promotion-tracking'

export interface CategoryPageProps {
  children: React.ReactNode
  slots?: SlotContentItem
  seo?: {
    pageTitle: string
    pageDescription: string
  }
  categoryId?: string
  name?: string
  categoryTree: Crumb[]
}

export interface SlotContentType extends ContentBody {
  default: SlotItem[]
}

const CATEGORY_BOTTOM_SCHEMA = 'https://www.iceland.co.uk/slot/category-bottom-slot.json'
export interface DisplaySlotsProps {
  slots: SlotContentItem
}

export const DisplaySlots = ({slots}: DisplaySlotsProps) => {
 
  const sendPromotionData = usePromotionDataTracking()

  const slotGroupName = slots._meta.name

  const promoContentForDataLayer = slots?.default?.map(obj => [{
    ...obj, 
    slotGroupName: slotGroupName 
  }]).filter(item => Object.keys(item).length > 0)

  slots?.default?.forEach(slot => {
    slot.slotGroupName = slotGroupName
  })

  useEffect(() => {
    if (promoContentForDataLayer) {
      sendPromotionData(promoContentForDataLayer)
    }
  }, [promoContentForDataLayer])
  
  return (
    <Stack gap={["10px", "16px"]} marginBottom={["10px", "16px"]}>
      <Slot data={slots} />
    </Stack>
  )
}

const CategoryPage = observer(
  ({children, slots, seo, categoryId, categoryTree, name}: CategoryPageProps) => {
    const bottomSlot =
      slots &&
      slots?.length > 0 &&
      slots?.map(
        (slotGroup: SlotContentType, idx: number) =>
          slotGroup?._meta?.schema === CATEGORY_BOTTOM_SCHEMA && (
            <DisplaySlots slots={slotGroup} key={`${slotGroup?._meta?.deliveryId}-${idx}`} />
          ),
      )

    const sendPageData = usePageDataTracking()

    let pageType = ''
    let pageCategory = ''
    let pageSubCategory = ''

    const {categories, status} = useCategoryStore()

    useEffect(() => {
      if (status !== 'done') {
        return
      }

      const category = categoryId && categoryId in categories ? categories[categoryId] : undefined

      if (category) {
        pageType = 'plp'
        const {isTopLevelCategory, parentCategoryTree, name} = category

        if (!isTopLevelCategory) {
          pageCategory = parentCategoryTree?.[0].name || ''
          pageSubCategory = name || ''
        } else {
          pageCategory = name || ''
        }
      }
      sendPageData(pageType, pageCategory, pageSubCategory)
    }, [status])

    return (
      <Container position="relative" paddingX="0px" paddingBottom="60px">
        <Seo
          title={seo?.pageTitle || `${name} | Iceland Foods`}
          description={seo?.pageDescription}
        />
        <Container top="0px" paddingX="0px" zIndex="header" position="relative">
          <Box paddingY="12px">
            <Breadcrumbs breadcrumbs={categoryTree} categoryId={categoryId} />
          </Box>
        </Container>
        <>
          {children}
          {bottomSlot}
        </>
      </Container>
    )
  },
)

export default CategoryPage
